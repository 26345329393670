import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,[_c(VLayout,{attrs:{"row":"","align-content-space-between":""}},[_c(VSpacer),_c(VFlex,[_c(VTextField,{attrs:{"label":"Søk","placeholder":"Søk etter navn eller ID...","append-icon":"mdi-magnify","single-line":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.centers,"search":_vm.search,"item-key":"centerId","sort-by":"lgVersions[0].name","disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){$event.stopPropagation();return _vm.showItemDialog(item)}}},[_c('td',[_vm._v(_vm._s(item.centerId))]),_c('td',[_vm._v(_vm._s(_vm.getUiVersion(item).name))]),_c('td',[_c('a',{attrs:{"href":_vm.getUiVersion(item).homePageUrl,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.getUiVersion(item).homePageUrl))])]),_c('td',{staticStyle:{"text-align":"center"}},[_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(item.hasWayfinding),expression:"item.hasWayfinding"}]},[_vm._v(" mdi-check ")])],1),_c('td',{staticStyle:{"text-align":"center"}},[_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(item.hasApp),expression:"item.hasApp"}]},[_vm._v(" mdi-check ")])],1),_c('td',{staticStyle:{"text-align":"center"}},[_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(item.hasGiftCard),expression:"item.hasGiftCard"}]},[_vm._v(" mdi-check ")])],1),_c('td',_vm._l((item.lgVersions),function(lgVersion){return _c('country-flag',{key:lgVersion.lgCode,attrs:{"country":_vm.countryCodeForLgCode(lgVersion.lgCode),"size":"small"}})}),1),_c('td',[_vm._v(_vm._s(_vm.formatDateTime(item.lastUpdated)))]),_c('td',[_c(VIcon,{attrs:{"color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_vm._v(" mdi-delete-forever ")])],1)])]}}])})],1),_c(VCardActions,[_c(VSpacer),_c('DialogEdit',{attrs:{"title":"Opprett kjøpesenter","create-model":_vm.createNewModel,"save":_vm.save,"support-languages":true,"width":900},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v(" mdi-playlist-plus ")])]}},{key:"edit",fn:function(ref){
var model = ref.model;
var language = ref.language;
return [_c('BasedataCenterEdit',{attrs:{"model":model,"language":language,"create-lg-version":_vm.createLgVersion}})]}}])})],1),_c('DialogEdit',{ref:"itemDialog",attrs:{"title":"Kjøpesenter","edit-title":"Rediger kjøpesenter","save":_vm.save,"support-languages":true,"close":_vm.closeEditDialog,"width":900},scopedSlots:_vm._u([{key:"view",fn:function(ref){
var model = ref.model;
var language = ref.language;
return [_c('BasedataCenterView',{attrs:{"model":model,"language":language},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})]}},{key:"edit",fn:function(ref){
var model = ref.model;
var language = ref.language;
return [_c('BasedataCenterEdit',{attrs:{"model":model,"language":language,"create-lg-version":_vm.createLgVersion},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }