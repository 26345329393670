import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}]},[_c(VCol,[_c(VSwitch,{attrs:{"label":"Vis kun åpne"},model:{value:(_vm.showOpenOnly),callback:function ($$v) {_vm.showOpenOnly=$$v},expression:"showOpenOnly"}})],1),_c(VSpacer),_c(VCol,[_c(VTextField,{attrs:{"label":"Søk","placeholder":"Søk etter navn eller ID...","append-icon":"mdi-magnify","single-line":"","dense":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"items":_vm.filteredItems,"headers":_vm.headers,"loading":_vm.loading,"search":_vm.search,"item-key":"storeId","sort-by":"name","footer-props":{ itemsPerPageOptions: [5, 8, 10, 20, 50] },"items-per-page":5,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c(VCheckbox,{attrs:{"dense":"","disabled":_vm.disabled},on:{"change":function($event){return _vm.includedChanged(item)}},model:{value:(item.included),callback:function ($$v) {_vm.$set(item, "included", $$v)},expression:"item.included"}})],1),_c('td',[_vm._v(_vm._s(item.storeId))]),_c('td',[_vm._v(_vm._s(item.name))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }