import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"items":_vm.editItems,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[(_vm.showPeriodDay(item, index))?_c('span',[_vm._v(_vm._s(_vm.weekdays[item.openDay]))]):_vm._e()]),_c('td',[(_vm.showPeriodDay(item, index))?_c(VSwitch,{attrs:{"dense":"","disabled":_vm.disabled},on:{"change":function($event){return _vm.openChanged(item, index)}},model:{value:(item.open),callback:function ($$v) {_vm.$set(item, "open", $$v)},expression:"item.open"}}):_vm._e()],1),_c('td',[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.timeMask),expression:"timeMask"}],attrs:{"placeholder":"tt:mm","disabled":_vm.disabled || !item.open,"dense":""},on:{"change":function($event){return _vm.openTimeChanged(item)}},model:{value:(item.openTime),callback:function ($$v) {_vm.$set(item, "openTime", $$v)},expression:"item.openTime"}})],1),_c('td',[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.timeMask),expression:"timeMask"}],attrs:{"placeholder":"tt:mm","disabled":_vm.disabled || !item.open,"dense":""},on:{"change":function($event){return _vm.closeTimeChanged(item)}},model:{value:(item.closeTime),callback:function ($$v) {_vm.$set(item, "closeTime", $$v)},expression:"item.closeTime"}})],1),_c('td',[(!_vm.disabled && _vm.allowDeletePeriod(item, index))?_c(VIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.deletePeriod(item, index)}}},[_vm._v(" mdi-delete-forever ")]):_vm._e()],1),_c('td',[(!_vm.disabled && _vm.allowAddPeriod(item, index))?_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.addPeriod(item, index)}}},[_vm._v("Legg til åpningstid")]):_vm._e()])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }